<template>
  <el-container class="layout-container">
    <!--mobilde ise sol tarafın tamamen kapatılması için v-if eklendi HS 10/06/2023 !!! -->
    <!--Görüşme odasındayken sol tarafın gözükmemesi ilave edildi. NC 13.09.2024 !!!-->
    <el-aside v-if="!(phoneView && isCollapse) && !$route.path.startsWith('/kullanici/gorusme-odasi')">
      <div class="logo">
        <img src="../assets/img/logo.svg" class="logo" v-if="isCollapse == false" />
        <img src="../assets/img/Compact.png" class="mobile" v-else />
      </div>
      <el-row class="tac">
        <el-col :span="24">
          <el-menu :default-active="activeMenu" class="el-menu-vertical-demo side-menu" :collapse="isCollapse" :router="true">
            <el-menu-item index="/kullanici">
              <i class="menu-icon-chat"></i>
              <span slot="title">{{ $localization("web-views-client-details-my-meets") }}</span>
            </el-menu-item>
            <el-menu-item index="/kullanici/profilim">
              <i class="menu-icon-user"></i>
              <span slot="title">{{ $localization("web-clientlayout-my-meets") }}</span>
            </el-menu-item>
            <!-- <el-menu-item index="/kullanici/notification" disabled>
              <i class="menu-icon-bell"></i>
              <span>Bildirimler</span>
            </el-menu-item> -->
            <el-menu-item index="/kullanici/recetelerim">
              <i class="menu-icon-pills"></i>
              <span slot="title">{{ $localization("web-clientlayout-prescriptions") }}</span>
            </el-menu-item>
            <el-menu-item index="/kullanici/sikca-sorulan-sorular">
              <i class="menu-icon-help"></i>
              <span slot="title">{{ $localization("web-clientlayout-faq") }}</span>
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
    </el-aside>
    <el-container>
      <el-header>
        <div class="left">
          <img src="../assets/img/Compact.png" class="mobile" />
          <div @click="isCollapse = !isCollapse" class="menu-collapse"></div>
          <!--mobilde 3 nokta kapalı hali gösteriliyordu artık açıldığı için gerek kalmadı kaldırıldı v-if eklendi HS 10/06/2023 !!! -->
          <!--<div @click="drawerMenu = !drawerMenu" class="menu-drawer"></div>-->
        </div>

        <div class="right">
          <!-- 14/06/2023 HS !!! görüşme başlat butonuna animasyon eklendi-->
          <el-button class="start-conversation" :class="this.$route.name != 'GorusmeBaslat' && this.$route.name != 'GorusmeOdasi' ? 'btn-anim' : ''" :disabled="converstationStatus" @click="startConversation">
            <div class="icon"></div>
            <div>{{ $localization("web-clientlayout-start-meet") }}</div>
          </el-button>
          <div class="buttons-group">
            <el-popover placement="bottom" width="300" popper-class="notifi-pop" trigger="click">
              <div class="notification-container">
                <div class="top">
                  <div class="left">
                    {{ $localization("web-clientlayout-notification") }}
                    <span v-if="$store.state.auth.notifyCount > 0">{{ $store.state.auth.notifyCount }}</span>
                  </div>
                  <div style="cursor: pointer" class="right" @click="deleteAllNotification()">{{ $localization("web-clientlayout-clear-all") }}</div>
                </div>
                <ul class="middle infinite-list" v-infinite-scroll="load">
                  <li class="infinite-list-item" v-for="(item, index) in notifyList" :key="index">
                    <div class="left near">
                      <div class="icon"></div>
                    </div>
                    <div class="right">
                      <div class="title">{{ item.Title }}</div>
                      <div class="time">
                        {{ $moment(item.CreatedDateTime).fromNow() }}
                      </div>
                    </div>
                    <div class="close">
                      <div @click="deleteNotification(item.ID, index)" class="icon"></div>
                    </div>
                  </li>
                </ul>
                <div class="bottom">
                  <div class="bot2" @click="markAllAsRead">
                    <div>{{ $localization("web-clientlayout-mark-read-all") }}</div>
                    <i class="icon-arrow"> </i>
                  </div>
                </div>
              </div>
              <el-button slot="reference" class="notification-button" v-if="user.UnreadNotificationCount > 0" @click="notifySet()" circle>
                <img src="@/assets/img/icons/bell-notification.svg" alt="" />
              </el-button>
              <el-button slot="reference" class="notification-button" v-else icon="menu-icon-bell" @click="notifySet()" circle></el-button>
            </el-popover>
            <!--mobilde düzgün gözükmesi için width 90px yapıldı HS 10/06/2023 !!! orjinal 150px -->
            <el-select class="primary-input" v-model="language" style="width: 90px" @change="setLanguage(language)">
              <el-option v-for="item in languageList" :key="item.ID" :label="item.Title" :value="item.LanguageCode" style="display: flex; align-items: center">
                <img :src="item.Image" style="height: 20px; width: 30px; margin-right: 10px" />
                {{ item.Title }}
              </el-option>
            </el-select>
            <div class="divider"></div>
            <div class="user-info" @click="goProfile()">
              <div class="user-avatar">
                <div class="short">{{ user.FirstName[0] }}{{ user.LastName[0] }}</div>
              </div>
              <div class="user-name">
                <span>{{ user.FirstName }} {{ user.LastName }}</span>
              </div>
            </div>
          </div>
          <!--mobilde düzgün gözükmesi için width 40px eklendi HS 10/06/2023 !!! -->
          <div class="menu-icon-logout" style="width: 40px" @click="logout()"></div>
          <audio src="../assets/sounds/Notification.mp3" playsinline ref="appointmentAlarm" style="display: none"></audio>
        </div>
      </el-header>
      <portal-target name="new-header"> </portal-target>
      <el-main>
        <vue-page-transition name="fade-in-up">
          <router-view />
        </vue-page-transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      languageList: [],
      language: JSON.parse(JSON.stringify(this.$store.state.auth.language)),
      activeMenu: this.$route.path,
      isCollapse: true,
      converstationStatus: false,
      //telefon görünümü için boolean değişken
      phoneView: true,
    };
  },
  name: "ClientLayout",
  async beforeMount() {
    await this.getLanguageList();
  },
  mounted() {
    if (window.innerWidth > 768) {
      //eğer ekran telefon boyutundaysa değişkenler set edildi
      this.isCollapse = false;
      this.phoneView = false;
    }
    this.$socket = this.$io(process.env.VUE_APP_SOCKETURL, {
      transports: ["websocket"],
      upgrade: false,
      query: {
        token: localStorage.getItem("token"),
      },
    });
    this.$store.commit("setSocket", this.$socket);
    this.$socket.off("reconnect_attempt");
    this.$socket.on("reconnect_attempt", () => {
      console.log("reconnect_attempt");
    });
    this.$socket.off("disconnect");
    this.$socket.on("disconnect", () => {
      console.log("disconnect");
    });
    this.$socket.off("subscribe_success");
    this.$socket.on("subscribe_success", (room) => {
      if (room == "presence-user-" + this.user.ID) {
        this.$socket.off("presence-user-" + this.user.ID + "-newAppointmentMessage");
        this.$socket.on("presence-user-" + this.user.ID + "-newAppointmentMessage", (messages) => {
          if (messages.data.Message.SenderUserId != this.user.ID) {
            this.$refs.appointmentAlarm.play(0);
          }
          this.$store.commit("addCurrentAppointmentMessage", messages.data.Message);
          this.$nextTick(() => {
            this.jQuery("#scroll-list").animate({ scrollTop: this.jQuery("#scroll-list").prop("scrollHeight") }, 250);
          });
        });

        this.$socket.off("presence-user-" + this.user.ID + "-forceSignOut");
        this.$socket.on("presence-user-" + this.user.ID + "-forceSignOut", () => {
          localStorage.removeItem("token");
          this.$store.state.auth.user = null;
          this.$router.push("/");
        });
        this.$socket.off("presence-user-" + this.user.ID + "-appointmentExpired");
        this.$socket.on("presence-user-" + this.user.ID + "-appointmentExpired", () => {
          this.$router.push({ path: "/kullanici/gorusme-baslat", query: { expired: true } });
        });
        this.$socket.off("presence-user-" + this.user.ID + "-youJoinedAnAppointmentFromAnotherWindowNowCloseThisOne");
        this.$socket.on("presence-user-" + this.user.ID + "-youJoinedAnAppointmentFromAnotherWindowNowCloseThisOne", () => {
          if (window.doNotThrowMe != true) {
            this.$router.push("/kullanici");
          }
        });
        this.$socket.off("presence-user-" + this.user.ID + "-doctorJoined");
        this.$socket.on("presence-user-" + this.user.ID + "-doctorJoined", (appointment) => {
          this.$message.success("Doktorunuz görüşmeye katıldı.");
          this.$refs.appointmentAlarm.play(0);
          this.$router.push("/kullanici/gorusme-odasi/" + appointment.data.AppointmentId);
        });
        this.$socket.off("presence-user-" + this.user.ID + "-paytrSuccess");
        this.$socket.on("presence-user-" + this.user.ID + "-paytrSuccess", (appointment) => {
          this.smsCloseDialog();
          this.$message.success("Mobil ödeme başarı ile tamamlandı.");
          window.location.href = appointment.data.RetRoomHtml;
          //this.$router.push("/kullanici/gorusme-odasi/" + appointment.data.AppointmentId);
        });
        this.$socket.off("presence-user-" + this.user.ID + "-paytrError");
        this.$socket.on("presence-user-" + this.user.ID + "-paytrError", (appointment) => {
          this.smsCloseDialog();
          this.$message.error(appointment.data.ErrorMessage);

          //zaten oradayız
          //this.$router.push("/kullanici/gorusme-baslat");
        });
      }
    });
    this.$socket.emit("subscribe", {
      channel: "presence-user-" + this.user.ID,
      token: "",
    });
  },

  methods: {
    async getLanguageList() {
      var res = await this.$client.post("/Localization/SystemLanguages");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.languageList = res.data.Data.Items;
      }
    },
    async setLanguage(langCode) {
      await this.$store.dispatch("setLanguageLocalisationKey", langCode);
    },
    async getAppointmentList() {
      await this.$store.dispatch("getListUserAppointments", {
        PageSize: 100,
        SearchTerm: null,
        Status: null,
        Page: 1,
      });
    },
    async startConversation() {
      await this.getAppointmentList();
      this.$nextTick(async () => {
        var appointment = await this.appointmentList.Appointments.filter((item) => (item.Status == 1 || item.Status == 2) && item.DoctorUser != null)[0];
        if (appointment) {
          this.$router.push("/kullanici/gorusme-odasi/" + appointment.ID);
        } else {
          this.$router.push({ path: "/kullanici/gorusme-baslat" });
        }
      });
    },
    async markAllAsRead() {
      var res = await this.$client.post("/Notification/MarkAllAsRead", {
        UserId: this.user.ID,
      });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.user.UnreadNotificationCount = 0;
      }
    },
    async deleteNotification(id, index) {
      var res = await this.$client.post("Notification/Delete", {
        Id: id,
      });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.notifyList.splice(index, 1);
        this.$message.success(this.$localization("web-clientlayout-notification-deleted-succesfuly"));
      }
    },
    async deleteAllNotification() {
      var res = await this.$client.post("Notification/DeleteAll", {
        UserId: this.user.ID,
      });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.notifyFilter.Page = 1;
        await this.$store.dispatch("getNotification", this.notifyFilter);
        this.$message.success(this.$localization("web-clientlayout-notifications-deleted-succesfuly"));
      }
    },

    goProfile() {
      this.$router.push({ name: "Profilim" });
    },
    startAppointment() {
      this.$router.push({ name: "GorusmeBaslat" });
    },
    async notifySet() {
      await this.$store.dispatch("getNotification", this.notifyFilter);
    },
    async load() {
      await this.$store.dispatch("loadNotification", this.notifyFilter);
      this.notifyFilter.Page++;
    },
    async logout() {
      await this.$client.post("/Account/SignOut");

      // #region Analyticss
      if (this.$gtag && this.user.Role == 1) {
        this.$gtag.event("sign_out", {
          platform: "web",
          user_id: this.user.ID,
        });
      }
      //#endregion

      localStorage.removeItem("token");
      this.$store.state.auth.user = null;
      this.$router.push({ name: "Login" });
    },
    smsCloseDialog() {
      this.$store.commit("setSmsShowDialog", false);
    },
  },
  computed: {
    appointmentList() {
      return this.$store.getters.getAppointments;
    },
    user() {
      return this.$store.getters.getUser;
    },
    notifyList() {
      return this.$store.getters.getNotification;
    },
    notifyFilter() {
      return this.$store.state.auth.notifyFilter;
    },
  },
  watch: {
    "$route.name": function (val) {
      if (val == "GorusmeOdasi") {
        this.converstationStatus = true;
      } else {
        this.converstationStatus = false;
      }
    },
    "$route.params": function () {
      this.activeMenu = this.$route.path;
    },
  },
};
</script>
